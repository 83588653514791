import { sendMessageToParent, types } from 'common/utils/iframe';
import React, {
  useEffect, useState, useCallback, useMemo
} from 'react';
import RunForm from 'v2/ui/run/RunForm/index';
import { runPropTypes } from 'common/propTypes';
import { emptyQuantConfig } from 'v2/modules/withRunForm/configs/index';

const propTypes = { run: runPropTypes };
const defaultProps = { run: {} };

const RunWidget = ({ run }) => {
  const [widgetConfig, setWidgetConfig] = useState({
    isLoading: true,
    quantConfig: {}
  });

  const handleMessage = useCallback((event) => {
    const { type, value } = event?.data ?? {};

    if (type === types.QUANT_CONFIG) {
      setWidgetConfig(() => ({
        isLoading: false,
        quantConfig: value ?? {}
      }));
    }
  }, []);

  useEffect(() => {
    sendMessageToParent({ type: types.RUN_WIDGET_LOADED });
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const quantConfig = useMemo(() => {
    return _.isEmpty(widgetConfig.quantConfig) ? emptyQuantConfig() : widgetConfig.quantConfig;
  }, [widgetConfig]);

  if (widgetConfig.isLoading) {
    return null;
  }

  return <RunForm {...run} quantConfig={quantConfig} />;
};

RunWidget.propTypes = propTypes;
RunWidget.defaultProps = defaultProps;

export default RunWidget;
