import React, {
  useContext, useCallback, useMemo, useState
} from 'react';
import {
  Dropdown, Tooltip, OverlayTrigger, Button
} from 'react-bootstrap';
import HoverMessage from 'common/components/HoverMessage';
import { OrderContext } from '../../OrderStore/index';

const DaysToExpiryFilter = () => {
  const dteOptions = Array(30 + 1).fill(0).map((_, i) => i);
  const { filterConfigs, setFilterConfigs } = useContext(OrderContext);
  const { daysToExpiry, isWeekDaysFilterActive, isDaysToExpiryFilterActive } = filterConfigs;
  const [isToggleOpen, setIsToggleOpen] = useState(true);

  const handleSelectAll = useCallback(() => {
    const updatedDays = daysToExpiry.length === dteOptions.length ? [] : dteOptions;
    setFilterConfigs({
      ...filterConfigs,
      daysToExpiry: updatedDays,
    });
  }, [daysToExpiry, filterConfigs, setFilterConfigs]);

  const handleOptionChange = useCallback(
    (value) => {
      const updatedDays = daysToExpiry.includes(value)
        ? daysToExpiry.filter((day) => day !== value)
        : [...daysToExpiry, value];
      setFilterConfigs({
        ...filterConfigs,
        daysToExpiry: updatedDays,
      });
    },
    [daysToExpiry, filterConfigs, setFilterConfigs]
  );

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {daysToExpiry.length ? daysToExpiry.join(', ') : 'None Selected'}
    </Tooltip>
  );

  const openFilter = useCallback(() => {
    setFilterConfigs({
      ...filterConfigs,
      isDaysToExpiryFilterActive: true,
      isWeekDaysFilterActive: false,
      weekDays: [],
      daysToExpiry: [],
    });
    setIsToggleOpen(true);
  }, [filterConfigs, setFilterConfigs, dteOptions]);

  const closeFilter = useCallback(() => {
    setFilterConfigs({
      ...filterConfigs,
      isDaysToExpiryFilterActive: false,
      isWeekDaysFilterActive: false,
      daysToExpiry: [],
    });
    setIsToggleOpen(false);
  }, [filterConfigs, setFilterConfigs]);

  const renderedOptions = useMemo(() => {
    const isChecked = daysToExpiry.length === dteOptions.length;
    return (
      <>
        <Dropdown
          show={isToggleOpen}
          onToggle={() => setIsToggleOpen(!isToggleOpen)}
          className="w-full dte-filter-dropdown"
        >
          <OverlayTrigger
            placement="top"
            overlay={renderTooltip}
            delay={{ show: 250, hide: 400 }}
          >
            <Dropdown.Toggle
              as={Button}
              variant="light"
              size="sm"
              className="dte-filter-toggle w-full"
            >
              Select DTE
            </Dropdown.Toggle>
          </OverlayTrigger>
          <Dropdown.Menu className="w-full p-3">
            <div className="det-filter-dropdown px-1 py-1">
              <label className="flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleSelectAll}
                  className="mr-2"
                />
                {isChecked ? 'Unselect All' : 'Select All'}
              </label>
            </div>
            {dteOptions.map((value) => (
              <div key={value} className="det-filter-dropdown px-1 py-1">
                <label className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={daysToExpiry.includes(value)}
                    onChange={() => handleOptionChange(value)}
                    className="mr-2"
                  />
                  {value === 0 ? 'Expiry' : `${value} DTE`}
                </label>
              </div>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <button
          className="dte-filter-btn btn border"
          data-toggle="tooltip"
          title="Clear Filter"
          type="button"
          onClick={closeFilter}
        >
          <i className="material-icons-outlined d-block tx-15">close</i>
        </button>
      </>
    );
  }, [dteOptions, daysToExpiry, handleOptionChange, handleSelectAll]);

  if (isWeekDaysFilterActive) return null;

  return (
    <div className="dte-filter">
      <HoverMessage
        title="Days to Expiry Filter"
        message="Select your trades using DTE (Days to expiry) to improve your strategy."
      >
        <button
          className={`btn btn-md btn-outline-primary text-nowrap copy-btn track
          dte-filter-btn mx-1 ${isDaysToExpiryFilterActive ? 'active' : ''}`}
          type="button"
          onClick={!isDaysToExpiryFilterActive ? openFilter : null}
        >
          DTE
        </button>
      </HoverMessage>

      {isDaysToExpiryFilterActive && renderedOptions}
    </div>
  );
};

export default DaysToExpiryFilter;
