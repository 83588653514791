import { BROKERS, XTS_API_VENDOR } from 'common/constants/index';

let xtsImageSource = 'https://encrypted-tbn0.gstatic.com';
xtsImageSource += '/images?q=tbn:ANd9GcSwr_LzBoU7RjcYjp21df6W-rFBEE9eI2qjEQ&usqp=CAU';

export const loginPageTopBrokers = [
  BROKERS.fyers.id,
  BROKERS.paytm.id,
  BROKERS.zerodha.id,
  BROKERS.angel_broking.id,
  BROKERS.aliceblue.id,
  BROKERS.upstox.id,
  BROKERS.fivepaisa.id,
  BROKERS.icici_direct.id,
  BROKERS.finvasia.id,
  BROKERS.dhan.id
];

export const brokerStructuredData = [
  {
    brokerConfig: BROKERS.fyers,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.fyers.image,
    imageClassName: '',
    sortOrder: 0
  },
  {
    href: BROKERS.paytm.href,
    brokerConfig: BROKERS.paytm,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.paytm.image,
    imageClassName: '',
    sortOrder: 6
  },
  {
    brokerConfig: BROKERS.icici_direct,
    brokerClassName: 'btn btn-light',
    name: 'ICICI direct',
    isShow: false,
    imagePath: BROKERS.icici_direct.image,
    imageClassName: '',
    sortOrder: 2
  },
  {
    href: BROKERS.angel_broking.href,
    brokerConfig: BROKERS.angel_broking,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.angel_broking.image_white,
    imageClassName: 'w-75',
    sortOrder: 3
  },
  {
    href: BROKERS.aliceblue.href,
    brokerConfig: BROKERS.aliceblue,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.aliceblue.image,
    imageClassName: '',
    sortOrder: 4
  },
  {
    href: BROKERS.zebull.href,
    brokerConfig: BROKERS.zebull,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.zebull.image,
    imageClassName: '',
    sortOrder: 5
  },
  {
    brokerConfig: BROKERS.flattrade,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.flattrade.image,
    imageClassName: '',
    sortOrder: 6
  },
  {
    brokerConfig: BROKERS.zerodha,
    brokerClassName: 'btn btn-light',
    name: BROKERS.zerodha.name,
    isShow: true,
    imagePath: BROKERS.zerodha.image,
    imageClassName: 'small',
    sortOrder: 40
  },
  {
    href: BROKERS.jainam_duck.href,
    brokerConfig: BROKERS.jainam_duck,
    brokerClassName: 'btn btn-light',
    name: 'Duck', // frontend Differently show to users.. so that not taken from Zeequant-Constant
    isShow: true,
    imagePath: BROKERS.jainam_duck.image,
    imageClassName: '',
    sortOrder: 15
  },
  {
    href: BROKERS.goodwill.href,
    brokerConfig: BROKERS.goodwill,
    brokerClassName: 'btn btn-light',
    name: BROKERS.goodwill.name,
    isShow: true,
    imagePath: BROKERS.goodwill.image,
    imageClassName: '',
    sortOrder: 12
  },
  {
    href: BROKERS.fivepaisa.href,
    brokerConfig: BROKERS.fivepaisa,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.fivepaisa.image,
    imageClassName: '',
    sortOrder: 9
  },
  {
    href: BROKERS.motilal_oswal.href,
    brokerConfig: BROKERS.motilal_oswal,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.motilal_oswal.image,
    imageClassName: '',
    sortOrder: 16
  },
  {
    brokerConfig: BROKERS.bigul,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.bigul.image,
    imageClassName: '',
    sortOrder: 19
  },
  {
    href: BROKERS.dhan.href,
    brokerConfig: BROKERS.dhan,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.dhan.image,
    imageClassName: '',
    sortOrder: 10
  },
  {
    brokerConfig: { id: XTS_API_VENDOR },
    brokerClassName: 'btn btn-light',
    name: '',
    isShow: true,
    imagePath: xtsImageSource,
    imageClassName: '',
    sortOrder: 15
  },
  {
    brokerConfig: BROKERS.finvasia,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.finvasia.image,
    imageClassName: '',
    sortOrder: 11
  },
  {
    brokerConfig: BROKERS.iifl,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.iifl.image,
    imageClassName: '',
    sortOrder: 13
  },
  {
    href: BROKERS.nuvama.href,
    brokerConfig: BROKERS.nuvama,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.nuvama.image,
    imageClassName: '',
    sortOrder: 14
  },
  {
    href: BROKERS.mastertrust.href,
    brokerConfig: BROKERS.mastertrust,
    brokerClassName: 'btn btn-light',
    isShow: false,
    imagePath: BROKERS.mastertrust.image,
    sortOrder: 17
  },
  {
    href: BROKERS.moneysukh.href,
    brokerConfig: BROKERS.moneysukh,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.moneysukh.image,
    sortOrder: 18
  },
  {
    brokerConfig: BROKERS.upstox,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.upstox.image,
    imageClassName: '',
    sortOrder: 7
  },
  {
    brokerConfig: BROKERS.bnrathi,
    brokerClassName: 'btn btn-light',
    name: 'B.N.Rathi',
    isShow: false,
    imagePath: BROKERS.bnrathi.image,
    imageClassName: '',
    sortOrder: 21
  },
  {
    brokerConfig: BROKERS.tradeplus,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.tradeplus.image,
    imageClassName: '',
    sortOrder: 22
  },
  {
    brokerConfig: BROKERS.rupeezy,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.rupeezy.image,
    imageClassName: '',
    sortOrder: 23
  },
  {
    href: BROKERS.smc_global.href,
    brokerConfig: BROKERS.smc_global,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.smc_global.image,
    sortOrder: 24
  },
  {
    href: BROKERS.enrich_money.href,
    brokerConfig: BROKERS.enrich_money,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.enrich_money.image,
    imageClassName: '',
    sortOrder: 1
  },
  {
    href: BROKERS.sharekhan.href,
    brokerConfig: BROKERS.sharekhan,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.sharekhan.image,
    imageClassName: '',
    sortOrder: 26
  },
  {
    brokerConfig: BROKERS.kotak_neo,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.kotak_neo.image,
    imageClassName: '',
    sortOrder: 27
  },
  {
    brokerConfig: BROKERS.sky_broking,
    brokerClassName: 'btn btn-light',
    name: 'Sky Broking',
    isShow: true,
    imagePath: BROKERS.sky_broking.image,
    imageClassName: '',
    sortOrder: 28
  },
  {
    brokerConfig: BROKERS.ac_agarwal,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.ac_agarwal.image,
    imageClassName: '',
    sortOrder: 29
  },
  {
    href: BROKERS.philip_capital.href,
    brokerConfig: BROKERS.philip_capital,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.philip_capital.image,
    imageClassName: '',
    sortOrder: 5
  },
  {
    href: BROKERS.samco.href,
    brokerConfig: BROKERS.samco,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.samco.image,
    imageClassName: '',
    sortOrder: 32
  },
  {
    brokerConfig: BROKERS.indira_securities,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.indira_securities.image,
    imageClassName: '',
    sortOrder: 33
  },
  {
    brokerConfig: { ...BROKERS.jainam_broking_retail, name: 'Jainam Broking' },
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.jainam_broking_retail.image,
    imageClassName: '',
    sortOrder: 34
  },
  {
    brokerConfig: BROKERS.fair_invest,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.fair_invest.image,
    imageClassName: '',
    sortOrder: 35
  },
  {
    brokerConfig: BROKERS.profitmart,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.profitmart.image,
    imageClassName: '',
    sortOrder: 36
  },
  {
    brokerConfig: BROKERS.hdfcsky,
    brokerClassName: 'btn btn-light',
    isShow: false,
    imagePath: BROKERS.hdfcsky.image,
    imageClassName: '',
    sortOrder: 37
  },
  {
    brokerConfig: BROKERS.invest_right,
    brokerClassName: 'btn btn-light',
    isShow: false,
    imagePath: BROKERS.invest_right.image,
    imageClassName: '',
    sortOrder: 38
  },
  {
    brokerConfig: BROKERS.trade_smart,
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.trade_smart.image,
    imageClassName: '',
    sortOrder: 97
  },
  {
    brokerConfig: { ...BROKERS.kasat, name: 'Kasat Securities' },
    brokerClassName: 'btn btn-light',
    isShow: true,
    imagePath: BROKERS.kasat.image,
    imageClassName: '',
    sortOrder: 98
  },
  {
    href: BROKERS.groww.href,
    brokerConfig: { ...BROKERS.groww, name: 'Groww' },
    brokerClassName: 'btn btn-light',
    isShow: false,
    name: BROKERS.groww.name,
    imagePath: BROKERS.groww.image,
    imageClassName: '',
    sortOrder: 99
  },
  {
    href: BROKERS.broker_stub.href,
    brokerConfig: BROKERS.broker_stub,
    brokerClassName: 'btn btn-light',
    name: BROKERS.broker_stub.name,
    isShow: window.allowBrokerStub,
    imagePath: BROKERS.broker_stub.image,
    imageClassName: '',
    sortOrder: 100
  }
];
