const types = {
  RUN: 'RUN',
  RUN_WIDGET_LOADED: 'RUN_WIDGET_LOADED',
  QUANT_CONFIG: 'QUANT_CONFIG',
};

const createMessage = (type, value) => ({
  timestamp: new Date().toISOString(),
  type,
  value,
});

const sendMessageToParent = (message, targetOrigin = '*') => {
  if (window?.parent?.postMessage) {
    window.parent.postMessage(message, targetOrigin);
  } else {
    console.warn('window.parent.postMessage is not available');
  }
};

export { createMessage, sendMessageToParent, types };
