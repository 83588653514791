import React from 'react';
import PropTypes from 'prop-types';
import { oneOfTypes } from 'common/propTypes';
import HoverMessage from 'common/components/HoverMessage';
import HtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import PlaceStoplossOrderToBrokerFieldSelector from './PlaceStoplossOrderToBrokerFieldSelector';

const propTypes = {
  isPlaceStoplossOrderToBroker: oneOfTypes,
  onChange: PropTypes.func.isRequired,
};
const defaultProps = { isPlaceStoplossOrderToBroker: '' };

const isIndividualStoplossAvailable = (cases = [], pipeConfigs = []) => {
  const checkIntents = (intents) => _.some(intents, (intent) => intent?.stopLoss?.value);

  const isStopLossPresentInCase = _.some(cases, (caseConfig) => {
    const { entry: { intents }, adjustments } = caseConfig;

    return (
      checkIntents(intents)
      || _.some(adjustments, (adjustment) => checkIntents(adjustment.intents))
    );
  });

  const isStopLossPresentInPipeConfig = _.some(pipeConfigs, (pipeConfig) => {
    const { config } = pipeConfig;
    return checkIntents(config?.legs);
  });

  return isStopLossPresentInCase || isStopLossPresentInPipeConfig;
};

const PlaceStoplossOrderToBroker = ({
  isPlaceStoplossOrderToBroker, onChange
}) => {
  const cases = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.cases'));
  const pipeConfigs = useSelector((state) => getSelectorBasedStoreValue(state, 'quantConfig.pipeConfigs'));
  const isAvailable = isIndividualStoplossAvailable(cases, pipeConfigs);
  if (!isAvailable) { return null; }

  const oneIndentation = ' ';
  let content = '<b>If yes,</b></br>QuantMan will place individual ';
  content += 'leg stoploss(SL-Limit) order to broker and as well as we also track the stoploss order.';
  content += '</br></br><b>If No,</b></br>';
  content += 'QuantMan will track your stoploss and close position if stoploss acheived. ';
  content += '<b>No SL-Limit order to broker<b>';

  return (
    <div className="row mb-4">
      <label className="col-md-3">
        Should Place individual leg stoploss(sl-l) order to broker?
        {oneIndentation}
        <HoverMessage
          title=""
          message={HtmlParser(content)}
        >
          <span className="material-icons-outlined text-muted tx-16 align-middle">
            info
          </span>
        </HoverMessage>
        {oneIndentation}
      </label>
      <div className="col-md-9 place-options">
        <PlaceStoplossOrderToBrokerFieldSelector
          optionType={isPlaceStoplossOrderToBroker}
          onOptionTypeChange={(value) => onChange(value)}
        />
      </div>
    </div>
  );
};

PlaceStoplossOrderToBroker.propTypes = propTypes;
PlaceStoplossOrderToBroker.defaultProps = defaultProps;

export default PlaceStoplossOrderToBroker;
