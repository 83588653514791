import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { EXPIRY_MONTHLY_DETAILS, EXPIRY_WEEKLY_DETAILS } from 'v2/common/constants/index';
import classNames from 'classnames';
import HoverMessage from 'common/components/HoverMessage';

const propTypes = {
  expiry: PropTypes.shape({
    index: PropTypes.number.isRequired,
    cycle: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool,
  onChangeExpiry: PropTypes.func.isRequired,
  errorConfig: PropTypes.shape({}),
  isIlliquidInfo: PropTypes.bool,
};
const defaultProps = {
  isDisabled: false,
  errorConfig: {},
  isIlliquidInfo: false,
};

const ExpirySelector = ({
  expiry, onChangeExpiry, isDisabled, errorConfig, isIlliquidInfo
}) => {
  const isErrorInExpiry = _.get(errorConfig, 'isErrorInExpiry', false);
  const isPremiumError = _.get(errorConfig, 'isPremiumError', false);
  const { index, cycle } = expiry;

  const expiryDetails = useMemo(() => {
    return { ...EXPIRY_WEEKLY_DETAILS, ...EXPIRY_MONTHLY_DETAILS };
  }, []);

  const selectedValue = useMemo(() => `${index}-${cycle}`, [index, cycle]);

  const mtcContent = useMemo(() => (
    <div>
      The monthly contracts, being comparatively less liquid than the weekly contracts,
      may result in significant slippages due to insufficient volume.
      <br />
    </div>
  ), []);

  const renderExpiryOption = useCallback((expiryDetail, value) => {
    const { label } = expiryDetail;

    return <option value={value} key={value}>{label}</option>;
  }, []);

  const selectClassName = classNames('', { 'is-invalid': isErrorInExpiry });

  const renderCrownImage = () => {
    if (!isPremiumError) return null;

    return (
      <img src="/v2Crown.png" className="v2-crown-image position-absolute sticky-top" alt="" />
    );
  };

  const renderInfoMessage = () => {
    return (
      <>
        <HoverMessage title="" message={mtcContent}>
          <span className="d-flex align-items-center material-icons-outlined info-icon ml-1">
            info
          </span>
        </HoverMessage>
      </>
    );
  };

  return (
    <div className="d-flex expiry-selector position-relative">
      <select
        className={selectClassName}
        disabled={isDisabled}
        value={selectedValue}
        onChange={(event) => {
          const { config } = expiryDetails[event.target.value];
          onChangeExpiry(config);
        }}
      >
        {_.map(expiryDetails, renderExpiryOption)}
      </select>
      {renderCrownImage()}
      {isIlliquidInfo && renderInfoMessage()}
    </div>
  );
};

ExpirySelector.propTypes = propTypes;
ExpirySelector.defaultProps = defaultProps;

export default memo(ExpirySelector);
