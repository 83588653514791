import React, { useContext } from 'react';

import { OrderContext } from 'ui/run/RunShow/OrderStore/index';
import withErrorBoundary from 'common/components/withErrorBoundary/index';
import PropTypes from 'prop-types';
import TradeTypeAnalytics from './components/TradeTypeAnalytics';
import InstrumentGroupAnalytics from './components/InstrumentGroupAnalytics';
import DayOfWeekAnalytics from './components/DayOfWeekAnalytics/index';
import YearAnalytics from './components/YearAnalytics';
// import CaseAnalytics from './components/CaseAnalytics';
import Header from './components/Header';
import MonthlyAnalytics from './components/MonthlyAnalytics';

const propTypes = {
  tradingType: PropTypes.string.isRequired
};
const defaultProps = {};

const TransactionsAnalytics = ({ tradingType }) => {
  const {
    isLoading, orders, completedTransactions
  } = useContext(OrderContext);

  if (_.isEmpty(orders) || isLoading) return null;

  const renderAnalyticsProgress = () => {
    if (!completedTransactions?.length) {
      return (
        <div className="text-center">
          <img className="no-data-found" src="/no-data.svg" alt="No Data Found" />
        </div>
      );
    }

    return (
      <div className="row analytics-progress-wrapper">
        <InstrumentGroupAnalytics />
        <TradeTypeAnalytics />
        <DayOfWeekAnalytics tradingType={tradingType} />
        <YearAnalytics />
        {/* <CaseAnalytics /> */}
        <MonthlyAnalytics />
      </div>
    );
  };

  return (
    <div className="transaction-analytics mt-3">
      <div className="card">
        <div className="card-header">
          <div className="card-title">Transactions Analytics</div>
        </div>
        <div className="card-body">
          <Header />
          {renderAnalyticsProgress()}
        </div>
      </div>
    </div>
  );
};

TransactionsAnalytics.propTypes = propTypes;
TransactionsAnalytics.defaultProps = defaultProps;

export default withErrorBoundary(TransactionsAnalytics);
