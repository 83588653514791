import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getSelectorBasedStoreValue } from 'v2/modules/withRunForm/redux/selector';
import { RUN_TYPES, ORDER_TYPES } from 'v2/common/constants/index';
import RunFormInstructions from 'ui/run/RunForm/components/RunFormInstructions';
import RunType from './RunType';
import OrderType from './OrderType';
import RunName from './RunName';
import Folder from './Folder';
import GranularitySelector from './GranularitySelector';
import HistoricDateRangePicker from './HistoricDateRangePicker/index';

const RunFormHeader = () => {
  const runType = useSelector((state) => getSelectorBasedStoreValue(state, 'runType'));
  const orderType = useSelector((state) => getSelectorBasedStoreValue(state, 'orderType'));
  const isLive = useMemo(() => runType === RUN_TYPES.live, [runType]);
  const isSecondBySecondEnabledForUser = _.get(
    window, 'SA_FEATURE_FLAGS.isRunGranularitySecondEnabled', false
  ) || window?.isAdmin;

  const shouldShowGranularity = !isLive && isSecondBySecondEnabledForUser;

  const { paper: { value: paper } } = ORDER_TYPES;
  const isActualOrder = useMemo(() => orderType !== paper && isLive, [orderType, paper, isLive]);

  return (
    <>
      <div className="card-wrapper p-4 pt-5 position-relative">
        <RunType />
        <div className="row">
          {isLive ? <div className="col-sm mb-3 mb-md-0"><OrderType /></div> : (
            <>
              <div className="col-sm mb-3 mb-md-0"><HistoricDateRangePicker /></div>
              <div className="col-sm mb-3 mb-md-0"><HistoricDateRangePicker isEndDate /></div>
            </>
          )}
          <div className="col-sm mb-3 mb-md-0"><RunName /></div>
          <div className="col-sm mb-3 mb-md-0"><Folder /></div>
          {shouldShowGranularity && <div className="col-sm mb-3"><GranularitySelector /></div>}
        </div>
      </div>
      {isActualOrder && (
        <RunFormInstructions orderType={orderType} />
      )}
    </>
  );
};

export default memo(RunFormHeader);
