import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import Modal from 'common/components/ModalComponent';
import { updateMandatoryPreferences, signOut } from 'common/api/user';
import { LANGUAGES } from './constants';

const propTypes = { redirectPath: PropTypes.string.isRequired };

const Preferences = ({ redirectPath }) => {
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [error, setError] = useState('');

  const handleSubmit = () => {
    if (!selectedLanguages.length) {
      setError('Please fill the field');
      return;
    }

    setError('');

    updateMandatoryPreferences(
      { comfortable_languages: selectedLanguages }
    ).then((res) => {
      if (res.status) {
        window.location.href = redirectPath;
      } else {
        setError('Something went wrong');
      }
    });
  };

  return (
    <Modal
      size="md"
      onClose={signOut}
      className="email-verification"
    >
      <form>
        <div className="form-group">
          <label htmlFor="languages">
            Select your preferred Languages
            :
          </label>
          <Typeahead
            id="languages"
            multiple
            options={LANGUAGES}
            selected={selectedLanguages}
            onChange={setSelectedLanguages}
            className="fixed-width-multiselect"
          />
        </div>
        <div className="row">
          {error && <p className="col-12 text-center text-danger ">{error}</p>}
          <div className="col-12 text-center">
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

Preferences.propTypes = propTypes;

export default Preferences;
