import React from 'react';
import PropTypes from 'prop-types';
import { orderModificationPropTypes } from 'common/propTypes';
import ConfigDisplayDetails from './ConfigDisplayDetails';

const propTypes = {
  entryOrderType: PropTypes.string.isRequired,
  exitOrderType: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  entryOrderModification: orderModificationPropTypes,
  exitOrderModification: orderModificationPropTypes,
  isPlaceOptionsEntryBuyOrdersFirst: PropTypes.bool,
  slOrderPlaceBasedOnPrice: PropTypes.string,
  limitOrderBufferPercentage: PropTypes.number,
  isPlaceStoplossOrderToBroker: PropTypes.bool,
  isSquareOffTransactionIfEntryFailed: PropTypes.bool
};

const defaultProps = {
  entryOrderModification: {},
  exitOrderModification: {},
  isPlaceOptionsEntryBuyOrdersFirst: null,
  slOrderPlaceBasedOnPrice: null,
  limitOrderBufferPercentage: null,
  isPlaceStoplossOrderToBroker: null,
  isSquareOffTransactionIfEntryFailed: null
};

const ViewConfigOrdersProduct = ({
  entryOrderType, exitOrderType, product, entryOrderModification,
  exitOrderModification, isPlaceOptionsEntryBuyOrdersFirst, slOrderPlaceBasedOnPrice,
  limitOrderBufferPercentage, isPlaceStoplossOrderToBroker, isSquareOffTransactionIfEntryFailed
}) => {
  const renderIsPlaceOptionsEntryBuyOrdersFirst = () => {
    if (isPlaceOptionsEntryBuyOrdersFirst === null) return null;

    return (
      <ConfigDisplayDetails
        title="Place orders sequenced for Margin Benefit (Ex: buy leg first sell after)"
        value={isPlaceOptionsEntryBuyOrdersFirst ? 'YES' : 'NO'}
      />
    );
  };

  const renderIsSquareOffTransactionIfEntryFailed = () => {
    if (isSquareOffTransactionIfEntryFailed === null) return null;

    return (
      <ConfigDisplayDetails
        title="Square Off Transaction if any entry is failed"
        value={isSquareOffTransactionIfEntryFailed ? 'YES' : 'NO'}
      />
    );
  };

  const getStructuredString = (modification) => {
    const { action, actionTimeInSeconds } = modification;
    let shotString = `, if order not executed in ${actionTimeInSeconds} seconds `;
    shotString += `(${_.startCase(action)})`;

    return shotString;
  };

  let entryShowString = _.startCase(entryOrderType);
  let exitShowString = _.startCase(exitOrderType);
  if (!_.isEmpty(entryOrderModification)) {
    entryShowString += getStructuredString(entryOrderModification);
  }
  if (!_.isEmpty(exitOrderModification)) {
    exitShowString += getStructuredString(exitOrderModification);
  }

  return (
    <div>
      <div className="card-header">
        <h5 className="m-0">Orders / Product</h5>
      </div>
      <div className="card-body">
        <div className="instrument-groups-input">
          <ConfigDisplayDetails title="Entry Order" value={entryShowString} />
          <ConfigDisplayDetails title="Exit Order" value={exitShowString} />
          <ConfigDisplayDetails title="Product" value={_.startCase(product)} />
          {renderIsPlaceOptionsEntryBuyOrdersFirst()}
          {slOrderPlaceBasedOnPrice
            && (
              <ConfigDisplayDetails
                title="SL and TP Order Based On Price"
                value={_.startCase(slOrderPlaceBasedOnPrice)}
              />
            )}
          {(limitOrderBufferPercentage !== null && limitOrderBufferPercentage !== 0) && (
            <ConfigDisplayDetails
              title="Limit Order Buffer Percentage"
              value={`${limitOrderBufferPercentage}%`}
            />
          )}
          {(isPlaceStoplossOrderToBroker !== null) && (
            <ConfigDisplayDetails
              title="Should place individual leg stoploss(sl-l) order to broker?"
              value={isPlaceStoplossOrderToBroker ? 'YES' : 'NO'}
            />
          )}
          {renderIsSquareOffTransactionIfEntryFailed()}
        </div>
      </div>
    </div>
  );
};

ViewConfigOrdersProduct.defaultProps = defaultProps;
ViewConfigOrdersProduct.propTypes = propTypes;

export default ViewConfigOrdersProduct;
