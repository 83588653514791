import React, {
  memo, useCallback, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { BFO_INSTRUMENTS } from 'v2/common/constants/index';
import { useFormContext, Controller } from 'react-hook-form';
import { LocaleUtils } from 'v2/common/utils/zeequantConstants';
import ErrorMesssage from '../ErrorMessage/index';

const propTypes = {
  instrument: PropTypes.string,
  onUpdateInstrument: PropTypes.func.isRequired,
  prependLabel: PropTypes.string,
};

const defaultProps = { instrument: '', prependLabel: '' };

const SingleInstrumentSelector = ({
  instrument: propsInstrument, onUpdateInstrument, prependLabel
}) => {
  const {
    errors, control
  } = useFormContext();
  const uniqueId = useMemo(() => _.uniqueId('instrument_'), []);
  const instruments = useMemo(() => LocaleUtils().getAllSymbols(), []);
  const instrument = useMemo(() => propsInstrument.replace(/NFO:|BFO:/, ''), [propsInstrument]);

  const options = useMemo(() => _.chain(instruments)
    .map((ins) => ({
      label: ins,
      value: `${_.includes(BFO_INSTRUMENTS, ins) ? 'BFO' : 'NFO'}:${ins}`
    })).value(), [instruments]);

  const onChangeInstrument = useCallback((selectedInsArray, onChange) => {
    const selectedIns = _.get(selectedInsArray, '[0].value', '');
    onUpdateInstrument(selectedIns);
    onChange(selectedInsArray);
  }, [onUpdateInstrument]);

  const selectedOptions = instrument ? [instrument] : [];
  const errorMessage = _.get(errors, `${uniqueId}.message`, '');

  const validatorConfig = useMemo(() => ({
    validate: (value) => {
      if (_.isEmpty(value)) return 'Please add instrument';

      return null;
    }
  }), []);

  return (
    <div>
      <div className="d-flex align-items-center gap-5 flex-wrap">
        <div className="d-flex align-items-center single-instrument-selector mb-md-0 flex-column">
          <div className="input-group">
            {prependLabel
              && (
                <div className="input-group-prepend">
                  <div className="input-group-text">{prependLabel}</div>
                </div>
              )}
            <Controller
              render={({ onChange, ref, name }) => (
                <Typeahead
                  className="custom-form-control"
                  isInvalid={!!errorMessage}
                  ref={ref}
                  clearButtons
                  id={name}
                  options={options}
                  selected={selectedOptions}
                  placeholder="Search..."
                  onChange={(newInstruments) => onChangeInstrument(newInstruments, onChange)}
                />
              )}
              control={control}
              defaultValue={selectedOptions}
              value={selectedOptions}
              name={uniqueId}
              rules={validatorConfig}
            />
          </div>
        </div>
      </div>
      <ErrorMesssage message={errorMessage} />
    </div>
  );
};

SingleInstrumentSelector.propTypes = propTypes;
SingleInstrumentSelector.defaultProps = defaultProps;

export default memo(SingleInstrumentSelector);
