import { TRADINGSYMBOL_REGEX } from 'common/constants/index';
import moment from 'moment';
import { LocaleUtils } from 'v2/common/utils/zeequantConstants';
import { INTENT_TYPES, SEGMENT_CONFIG } from 'v2/common/constants/index';
import { getSlippageBasedOrders } from '../../OrderStore/helper';

const Holidays = LocaleUtils().getHolidayDates();
const WeekendWorkingDays = LocaleUtils().getWeekendWorkingDates();

const weekdayFormatter = new Intl.DateTimeFormat('en-IN', { weekday: 'long' });

export const getWeekdayAbbreviation = _.memoize((orderTime) => {
  const date = new Date(orderTime);
  return weekdayFormatter.format(date);
}, (orderTime) => new Date(orderTime).toDateString());

const calculateWeekends = (startDay, totalDays) => {
  if (startDay < 6) {
    const modDays = totalDays % 7;
    return (
      Math.floor(totalDays / 7) * 2
      + (modDays > 7 - startDay ? Math.min(modDays - (7 - startDay), 2) : 0)
    );
  }
  const modDays = totalDays % 7;
  return (
    1 + Math.floor(totalDays / 7) * 2 + (modDays > (startDay === 6 ? 0 : 5) ? 1 : 0)
  );
};

export const getDaysToExpiry = _.memoize((parseSymbolData, orderDate) => {
  if (_.isEmpty(parseSymbolData?.expiryDay || parseSymbolData?.symbol)) {
    return null;
  }

  const startDate = moment(orderDate, 'YYYY-MM-DD');
  const endDate = moment(parseSymbolData.expiryDay, 'YYYY-MM-DD');

  const totalDays = endDate.diff(startDate, 'days');

  const startDay = startDate.day();
  let weekends = 0;

  weekends = calculateWeekends(startDay, totalDays);

  const holidaysInRange = Holidays.filter((holiday) => moment(holiday).isBetween(
    startDate, endDate, 'day', '[]'
  ));

  const weekendWorkingDaysInRange = WeekendWorkingDays.filter((weekendWork) => moment(weekendWork).isBetween(
    startDate, endDate, 'day', '[]'
  ));

  const businessDays = (totalDays - weekends - holidaysInRange.length) + weekendWorkingDaysInRange.length;

  return businessDays;
},
(parseSymbolData, orderDate) => {
  return `${parseSymbolData?.symbol}-${parseSymbolData?.expiryDay}-${orderDate}`;
});

export const getInstrumentType = _.memoize((tradingSymbol, optionIndex) => {
  if (tradingSymbol.includes('FUT')) return SEGMENT_CONFIG.future;
  if (optionIndex !== null && optionIndex !== undefined && !Number.isNaN(Number(optionIndex))) {
    return SEGMENT_CONFIG.option;
  }
  return SEGMENT_CONFIG.equity;
}, (tradingSymbol, optionIndex) => `${tradingSymbol}-${optionIndex}`);

export const getParseTradingSymbol = _.memoize((tradingSymbol) => {
  const match = TRADINGSYMBOL_REGEX.exec(tradingSymbol);
  if (match && match.groups) {
    const {
      date, month_name: month, year, symbol
    } = match.groups;
    const fullYear = year ? `20${year}` : null;

    let expiryDay = null;
    if (date && month && year) {
      expiryDay = moment(`${date}-${month}-${fullYear}`, 'DD-MMM-YYYY').format('YYYY-MM-DD');
    }

    return {
      symbol: symbol || null,
      expiryDay,
    };
  }
  console.error('Invalid_Trading_Symbol', tradingSymbol);
  return null;
}, (tradingSymbol) => tradingSymbol);

export const getBackTestFilteredStrategies = (orders, filterConfigs) => {
  const {
    weekDays, daysToExpiry, legs, slippageConfig
  } = filterConfigs;
  const { slippage, slippageType } = slippageConfig || {};

  if (_.isEmpty(weekDays)
      && _.isEmpty(daysToExpiry)
      && _.isEmpty(legs)
      && _.isEmpty(slippageConfig)) { return orders; }

  return _.chain(orders)
    .filter(({ day }) => {
      if (_.isEmpty(weekDays)) return true;
      if (weekDays && _.includes(weekDays, day)) return true;

      return false;
    })
    .filter(({ days_to_expiry: DaysToExpiry }) => {
      if (_.isEmpty(daysToExpiry)) return true;
      if (daysToExpiry && _.includes(daysToExpiry, DaysToExpiry)) return true;
      return false;
    })
    .filter(({ leg }) => {
      if (_.isEmpty(legs)) return true;
      if (legs && _.includes(legs, leg)) return true;
      return false;
    })
    .thru((filteredOrders) => {
      if (_.isEmpty(slippageConfig) || slippage === 0) {
        return filteredOrders;
      }
      return getSlippageBasedOrders(filteredOrders, slippage, slippageType);
    })
    .value();
};

export const transformCaseIntentsStructures = (cases) => {
  const intentMapObject = {};
  const intentArray = [];

  cases.forEach((caseConfig, caseIndex) => {
    const entryIntents = caseConfig.entry?.intents || [];
    const adjustmentIntents = caseConfig.adjustments || [];

    const entryMap = {};
    const entryIntentArray = [];
    entryIntents.forEach((intent, entryIndex) => {
      if (intent.type !== INTENT_TYPES.OpenBasket) {
        const {
          label = null, segment: type = null, caseIndex: caseIdx = null, adjustmentConfigIndex = null,
          optionConfigIndex = null
        } = intent?.identifier ?? {};
        entryMap[entryIndex] = {
          label,
          type,
          caseIdx,
          adjustmentConfigIndex,
          optionConfigIndex
        };
        entryIntentArray.push({
          id: label || '',
          label: `leg ${label || 'N/A'}`,
          type
        });
      }
    });

    const adjustmentMap = {};
    const adjustmentIntentArray = [];
    adjustmentIntents.forEach((adjustment, adjustmentIndex) => {
      const intentLabels = [];
      (adjustment.intents || []).forEach((intent) => {
        if (intent.type !== INTENT_TYPES.OpenBasket) {
          const {
            label = null, segment: type = null, caseIndex: caseIdx = null, adjustmentConfigIndex = null,
            optionConfigIndex = null
          } = intent?.identifier ?? {};
          intentLabels.push({
            label,
            type,
            caseIdx,
            adjustmentConfigIndex,
            optionConfigIndex
          });
          adjustmentIntentArray.push({
            id: label || '',
            label: `leg ${label || 'N/A'}`,
            type
          });
        }
      });
      adjustmentMap[adjustmentIndex] = intentLabels;
    });

    intentMapObject[caseIndex] = {
      entry: entryMap,
      adjustments: adjustmentMap
    };
    intentArray.push(...entryIntentArray, ...adjustmentIntentArray);
  });

  return { intentMapObject, intentArray };
};

export const getLegLable = (
  intentMapObject, caseIndex, adjustmentIndex, optionIndex, instrumentType
) => {
  const caseData = intentMapObject[caseIndex];
  if (!caseData) return null;

  const matchCriteria = {
    caseIdx: Number(caseIndex),
    adjustmentConfigIndex: adjustmentIndex === 'null' ? null : Number(adjustmentIndex),
    optionConfigIndex: optionIndex === 'null' ? null : Number(optionIndex),
    type: instrumentType
  };

  if (caseData.adjustments && caseData.adjustments[adjustmentIndex]) {
    const adjustmentLeg = caseData.adjustments[adjustmentIndex]
      .find((leg) => _.isMatch(leg, matchCriteria));
    if (adjustmentLeg) return adjustmentLeg.label;
  }

  if (caseData.entry) {
    const entryLeg = Object.values(caseData.entry).find((leg) => _.isMatch(leg, matchCriteria));
    if (entryLeg) return entryLeg.label;
  }

  return null;
};
