import React from 'react';
import PropTypes from 'prop-types';
import SignalSentence from 'common/components/SignalSentence';
import { runPropTypes, outputSchemaPropTypes } from 'common/propTypes';

const propTypes = {
  run: runPropTypes.isRequired,
  outputSchema: outputSchemaPropTypes,
  isFiltersApplied: PropTypes.bool,
};
const defaultProps = {
  outputSchema: [],
  isFiltersApplied: false
};

const NoOrdersMessage = ({ run, outputSchema, isFiltersApplied }) => {
  const { run_type: runType, status, quant_config: quantConfig } = run;
  const orderConfigs = _.get(quantConfig, 'orderConfigs', []);

  let title;
  let message;
  if (status === 'started') {
    title = 'Starting backtest seems to be stuck';
    message = 'Starting backtest seems to be stuck, please contact the support team';
  } else if (runType === 'live' && status !== 'completed') {
    title = 'No entry signals achieved yet.';
    message = 'No orders yet. When entry signal conditions are met, orders will be made.'
      + 'Your entry signals are :';
  } else if (runType === 'historic' && status === 'completed' && isFiltersApplied) {
    title = 'No Orders Generated with Current Filters';
    message = 'The applied filters did not result in any matching orders. '
    + 'Adjust the filters or reset them to see all available orders.';
  } else {
    title = 'No entry signals achieved.';
    message = 'No orders made. Please check the configuration. Configured entry signals are:';
  }

  const entrySignalConfigs = _.map(orderConfigs, (orderConfig) => {
    return _.get(orderConfig, 'entrySignalConfigs');
  });
  const entrySignalsSentences = _.map(entrySignalConfigs, (entrySignalConfig, index) => {
    return (
      <samp key={index} className="badge badge-light border">
        <SignalSentence
          signalConfigs={entrySignalConfig}
          outputSchema={outputSchema}
        />
      </samp>
    );
  });

  return (
    <div className="transaction-detail-card d-flex justify-content-center align-items-center mt-5">
      <div className="toast show no-orders-toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header">
          <strong className="mr-auto">{title}</strong>
          {/* <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button> */}
        </div>
        <div className="toast-body text-center">
          {message}
          <div className="m-4">{entrySignalsSentences}</div>
        </div>
      </div>
    </div>
  );
};

NoOrdersMessage.defaultProps = defaultProps;
NoOrdersMessage.propTypes = propTypes;

export default NoOrdersMessage;
