import React, { memo, useCallback } from 'react';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';

import HoverMessage from 'common/components/HoverMessage';
import useCases from 'v2/modules/withRunForm/hooks/useCases/index';
import Case from './Case/index';

const propTypes = {};
const defaultProps = {};

const Cases = () => {
  const {
    cases,
    onAddCase,
    onDeleteCase,
    activeCaseIndex,
    onChangeActiveCaseIndex,
    isMaximumCasesExceeded,
    isShowAddCaseHoverMessage,
    isSimpleMode,
    intentDependencies,
    onCloneCase,
    errorConfigs,
    errorMessage,
    isHiddenSignal
  } = useCases();

  let renderMessage = '<b> Please purchase a <b>Premium plan to use this feature.</b>';
  renderMessage += '<img src="/v2Crown.png" class="v2-crown-image" alt="" /></b>';

  const renderAddCaseButton = (
    <button
      className="
            custom_outline__btn tx-12
            font-weight-semi d-flex
            align-items-center mb-2"
      type="button"
      disabled={isMaximumCasesExceeded}
      onClick={onAddCase}
    >
      <i className="material-icons-outlined tx-14 mr-1">library_add</i>
      Add Case
    </button>
  );

  const renderHoverMessage = (
    <HoverMessage
      title="Premium Plan Required"
      message={ReactHtmlParser(renderMessage)}
      isOpen={isShowAddCaseHoverMessage}
    >
      {renderAddCaseButton}
    </HoverMessage>
  );

  const renderCaseIcon = useCallback((idx) => {
    return (
      <div className="d-flex flex-row align-items-center gap-5">
        <i className="material-icons-outlined tx-16" onClick={() => onCloneCase(idx)}>file_copy</i>
        {(cases?.length !== 1) && (
          <i
            className="close-icon"
            onClick={() => {
              onDeleteCase(idx);
              setTimeout(() => onChangeActiveCaseIndex(0), 100);
            }}
          >
            <img src="/v2-image/v2_close.svg" width="11" height="11" alt="close" />
          </i>
        )}
      </div>
    );
  }, [cases.length, onDeleteCase, onChangeActiveCaseIndex, onCloneCase]);

  const renderCaseIndex = useCallback((c, idx) => {
    const labelClassName = classNames('nav-item', { active: idx === activeCaseIndex });

    return (
      <div className={labelClassName} key={idx} onClick={() => onChangeActiveCaseIndex(idx)}>
        <span className="nav-link">
          Case
          {' '}
          {idx + 1}
        </span>
        {renderCaseIcon(idx)}
      </div>
    );
  }, [activeCaseIndex, onChangeActiveCaseIndex, renderCaseIcon]);

  const renderBtn = () => {
    if (isSimpleMode) return null;

    return isShowAddCaseHoverMessage ? renderHoverMessage : renderAddCaseButton;
  };

  const renderCheckbox = () => {
    return (
      <div className="form-check mb-3 d-flex align-items-center">
        <label className="form-check-label mr-2" htmlFor="caseAlreadyEntered">
          Can Enter if Another Case Already Entered
        </label>
        <input
          className="form-check-input"
          type="radio"
          name="caseAlreadyEntered"
          id="caseAlreadyEntered"
          value=""
          tabIndex="-100"
          checked={false}
          disabled
        />
        <label className="form-check-label" htmlFor="caseAlreadyEntered" />
      </div>
    );
  };

  const renderContentCase = useCallback((caseConfig, idx) => {
    return (
      <span className={`${idx === activeCaseIndex ? '' : 'hidden-for-future'}`} key={idx}>
        <Case
          intentDependencies={intentDependencies}
          caseConfig={caseConfig}
          caseIndex={idx}
          errorMessage={errorMessage}
          isHiddenSignal={isHiddenSignal}
          errorConfigs={errorConfigs}
        />
      </span>
    );
  }, [activeCaseIndex, intentDependencies, errorMessage, isHiddenSignal, errorConfigs]);

  return (
    <div className="cases-wrap">
      <div className="nav nav-tabs">
        {!isSimpleMode && <div className="d-flex nav-items-wrap">{_.map(cases, renderCaseIndex)}</div>}
        {renderBtn()}
      </div>
      <div className="tab-content">
        {!isSimpleMode && renderCheckbox()}
        {_.map(cases, renderContentCase)}
      </div>
    </div>
  );
};

Cases.propTypes = propTypes;
Cases.defaultProps = defaultProps;

export default memo(Cases);
