import RunSummary from 'modules/RunSummary/index';
import React, { useContext } from 'react';
import { RUN_TYPES } from 'common/constants/index';
import { RUN_STATUS_CODES } from 'common/configs/runStatus';
import NoOrdersMessage from './NoOrdersMessage';
import RunConfig from './RunConfig';
import RunErrorMessage from './RunErrorMessage';
import RunTitleAndActions from './RunTitleAndActions/index';
import TransactionDetail from './TransactionDetail/index';
import RunDetail from './RunDetail/index';
import { OrderContext } from '../OrderStore/index';
import { runShowDetailsPropTypes } from './propTypes';
import LiveDetails from './LiveDetails/index';
import CreditDetails from './CreditDetails';
import SlippageInputDetails from './SlippageInputDetails';
import WeekDaysFilter from './BackTestFilters/WeekDaysFilter';
import DaysToExpiryFilter from './BackTestFilters/DaysToExpiryFilter';
import LegsFilter from './BackTestFilters/LegsFilter';

const propTypes = runShowDetailsPropTypes.isRequired;

const defaultProps = { instrumentGroups: [], outputSchema: [] };

const RunShowDetails = ({
  run, shouldRenderLoadingSpinner, jobIds, instrumentGroups, shouldShowFilter, renderFilterOptions,
  currentUserDefaultFolderId, outputSchema, strategyDetails, currentUser,
  run: {
    run_type: runType, status, error_info: errorInfo = {}, quant_config: quantConfig,
    is_public: isPublic, is_quarantine: isQuarantine, granularity
  } // props includes runshow and show.html.erb (run show) file
}) => {
  const { COMPLETED, STARTED } = RUN_STATUS_CODES;
  const { orders, isLoading, filterConfigs: { weekDays, daysToExpiry, legs } } = useContext(OrderContext);

  const pipeConfigs = _.get(quantConfig, 'pipeConfigs', []);
  const tradingType = _.get(quantConfig, 'tradingType', '');
  const isBacktest = runType === RUN_TYPES.historic;

  const renderQuarantineMessage = () => {
    const oneIndentation = ' ';

    return (
      <div className="alert alert-sm alert-danger mb-2">
        Highly skewed results(win/loss ratio) have been found this run.
        {oneIndentation}
        Backtesting is based on minute by minute data and
        {oneIndentation}
        some people create backtests in such way that it creates a lot profit.
        {oneIndentation}
        To prevent misusing/misguiding/selling these artificially-engineered backtests,
        {oneIndentation}
        this run has been quarantined.
        {oneIndentation}
        This backtest cannot be viewed by others, even though it is marked as public.
        {oneIndentation}
        For any further queries, contact support.
      </div>
    );
  };

  const renderNoOrderMessage = () => {
    switch (status) {
      case STARTED: return !shouldRenderLoadingSpinner;
      case COMPLETED: return !isLoading && !orders.length; // backtest call waiting
      default: return false;
    }
  };

  const renderRunConfig = () => (
    <div className="run-config-card hidden-for-future">
      <div className="m-t-30 m-b-30 card">
        <div className="card-body"><RunConfig quantConfig={quantConfig} /></div>
      </div>
    </div>
  );

  const renderBacktestShowPage = () => {
    if (renderNoOrderMessage()) {
      return (
        <NoOrdersMessage
          run={run}
          outputSchema={outputSchema}
          isFiltersApplied={!_.isEmpty(weekDays) || !_.isEmpty(daysToExpiry) || !_.isEmpty(legs)}
        />
      );
    }

    return (
      <div>
        <RunSummary run={run} />
        <TransactionDetail run={run} tradingType={tradingType} />
      </div>
    );
  };

  const renderLiveRunShowPage = () => (
    <LiveDetails
      instrumentGroups={instrumentGroups}
      pipeConfigs={pipeConfigs}
      run={run}
      currentUser={currentUser}
      jobIds={jobIds}
    />
  );

  return (
    <div className="run-show">
      {(runType === 'historic' && isPublic && isQuarantine) && renderQuarantineMessage()}
      <RunTitleAndActions
        run={run}
        outputSchema={outputSchema}
        currentUserDefaultFolderId={currentUserDefaultFolderId}
        strategyDetails={strategyDetails}
        currentUser={currentUser}
        instrumentGroups={instrumentGroups}
      />
      <RunErrorMessage status={status} errorInfo={errorInfo} />
      <RunDetail run={run} />
      <div className="d-flex justify-content-between gap-10 flex-wrap mb-1">
        <CreditDetails
          runType={runType}
          granularity={granularity}
          isConsumed
          startDate={_.get(run, 'start_date', '')}
          endDate={_.get(run, 'end_date', '')}
          instrumentGroups={instrumentGroups}
          cases={_.get(quantConfig, 'cases', [])}
        />
        <SlippageInputDetails />
        {shouldShowFilter && (
          <div className="d-flex align-items-center">
            <label className="col-form-label col-form-label-sm mr-2">Instruments</label>
            {renderFilterOptions()}
          </div>
        )}
        {/* filter for runshowPage, this will come from renderProps(runshow) */}
      </div>
      {isBacktest
        && (
        <>
          <div className="backtest-filter">
            <div className="main-head mb-1 ml-1">
              <div>Fliter by</div>
            </div>
            <div className="backtest-filter-container">
              <div className="mb-2 days-filter ml-1">
                <WeekDaysFilter />
                <DaysToExpiryFilter />
              </div>
              <div className="leg-filter">
                <LegsFilter />
              </div>
            </div>
          </div>
          <hr className="mb-0 mt-0" />
        </>
        )}
      {isBacktest && renderBacktestShowPage()}
      {!isBacktest && renderLiveRunShowPage()}
      {renderRunConfig()}
    </div>
  );
};

RunShowDetails.propTypes = propTypes;
RunShowDetails.defaultProps = defaultProps;

export default RunShowDetails;
