import { onCreateStrategyAccess } from 'common/api/strategyAccess';
import { STRATEGY_ACCESS_STATUS } from 'common/constants/index';
import { strategyPropTypes } from 'common/propTypes';
import React, { useState } from 'react';
import { buttonTypesInStrategy, handleDisabledButtonInMarketPlace } from 'ui/Strategies/helper';
import { initiateUserAuth } from 'common/auth/modalLogin';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';

const propTypes = { strategy: strategyPropTypes.isRequired };

const defaultProps = {};

const RequestTypeButton = ({ strategy: propsStrategy }) => {
  const isUserLoggedIn = _.get(window, 'isUserSignedIn', false);
  const [strategy, setStrategy] = useState(propsStrategy);
  const { id } = strategy;
  const strategyAccess = _.get(strategy, 'strategy_access', {});
  const status = _.get(strategyAccess, 'status', '');

  const onRequest = () => {
    if (isUserLoggedIn) {
      onCreateStrategyAccess({ strategy_id: id, status: 'requested' }).then((result) => {
        const { strategy_access: access } = result;
        setStrategy({ ...strategy, strategy_access: access });
      });
      return null;
    }

    initiateUserAuth().then(() => {
      window.location.reload();
    }).catch(() => {
      reactToastify('Unauthorized, Please Login and continue', TOASTIFY_TYPES.ERROR);
    });

    return null;
  };

  const getButtonName = () => {
    if (status === STRATEGY_ACCESS_STATUS.REQUESTED) return 'Requested';
    if (status === STRATEGY_ACCESS_STATUS.PAID) return 'Approved';
    if (status === STRATEGY_ACCESS_STATUS.REJECTED) return 'Rejected';

    return 'Request';
  };

  const { isDisabled, message } = handleDisabledButtonInMarketPlace(
    strategy, buttonTypesInStrategy.requestType
  );
  return (
    <button
      className="request-btn btn btn-sm btn-primary btn-block"
      type="button"
      data-toggle="tooltip"
      title={message}
      disabled={isDisabled}
      onClick={() => onRequest()}
    >
      {getButtonName()}
    </button>
  );
};

RequestTypeButton.propTypes = propTypes;
RequestTypeButton.defaultProps = defaultProps;

export default RequestTypeButton;
