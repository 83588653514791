import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ModalComponent from 'common/components/ModalComponent';
import ReactHtmlParser from 'react-html-parser';
import { sendOtpToBrokerUser, validateOtpToBrokerUser } from 'common/api/user';
import ErrorMesssage from 'common/components/ErrorMesssage';
import { reactToastify, TOASTIFY_TYPES } from 'common/utils/reactToastify';
import { getBrokerImage, reDirectPage } from '../config';
import UserInput from '../CommonComponents/UserInput';

const propTypes = {
  broker: PropTypes.string.isRequired,
  onCloseModal: PropTypes.func.isRequired
};

const defaultProps = {};

const Bigul = ({ broker, onCloseModal }) => {
  const [configs, setConfigs] = useState({ clientId: '', password: '', otp: '' });
  const { clientId, password, otp } = configs;
  const [isSendOtpClicked, setIsSendOtpClicked] = useState(false);
  const isClientAndPasswordPresent = clientId && password;
  const [errorMessage, setErrorMessage] = useState('');

  const onSendOtp = (isResendOtp = false) => {
    sendOtpToBrokerUser({ client_id: clientId, password }, isResendOtp, broker).then((res) => {
      const { status, message } = res;
      if (!status) { setErrorMessage(message); } else {
        reactToastify(
          'OTP send successfully',
          TOASTIFY_TYPES.SUCCESS
        );
        setIsSendOtpClicked(true);
      }
    }).catch(() => { });
  };

  const onValidate = () => {
    validateOtpToBrokerUser({ client_id: clientId, password, otp }, broker).then((res) => {
      const { status, token, message } = res;
      if (!status) { setErrorMessage(message); } else {
        reDirectPage({ client_id: window.btoa(clientId), token }, broker, true);
      }
    }).catch(() => { });
  };

  const modalProps = {
    onClose: onCloseModal,
    shouldShow: true,
    size: 'sm',
    onSaveid: `btn-${broker}`,
    onSave: !isSendOtpClicked ? () => onSendOtp() : () => onValidate(),
    okBtnTitle: !isSendOtpClicked ? 'Send OTP' : 'Login',
    isOkBtnDisabled: !isClientAndPasswordPresent
  };

  const onHandleConfig = (key, value) => {
    setConfigs({ ...configs, [key]: value });
    if (errorMessage) { setErrorMessage(''); }
  };

  return (
    <ModalComponent {...modalProps}>
      <div className="mb-3">
        {ReactHtmlParser(getBrokerImage(broker))}
        <label className="mt-2">Client Id:</label>
        <UserInput
          id={`${broker}-client-id`}
          value={clientId}
          isDisabled={isSendOtpClicked}
          onHandleChange={(value) => { onHandleConfig('clientId', _.toUpper(value)); }}
        />
        <label className="mt-2">Password:</label>
        <UserInput
          id={`${broker}-password`}
          value={password}
          isDisabled={isSendOtpClicked}
          type="password"
          onHandleChange={(value) => { onHandleConfig('password', value); }}
        />
        {isSendOtpClicked && (
          <div className="text-right mb-2">
            <button className="btn btn-link btn-sm" type="button" onClick={() => { onSendOtp(true); }}>
              Re-send OTP ?
            </button>
          </div>
        )}

        {isSendOtpClicked && (
          <div className="mt-2">
            <label>TOTP/OTP</label>
            <UserInput
              id={`${broker}-otp`}
              value={otp}
              onHandleChange={(value) => { onHandleConfig('otp', value); }}
            />
          </div>
        )}

        <ErrorMesssage error={{ message: errorMessage }} />
      </div>
    </ModalComponent>
  );
};

Bigul.propTypes = propTypes;
Bigul.defaultProps = defaultProps;

export default Bigul;
