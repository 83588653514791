import React, { useContext } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { OrderContext } from '../../OrderStore/index';

const LegsFilter = () => {
  const { filterConfigs, setFilterConfigs, intentArray } = useContext(OrderContext);

  const handleLegsUpdate = (selectedLegs) => {
    setFilterConfigs({
      ...filterConfigs,
      legs: selectedLegs.map((selection) => selection.id),
    });
  };

  return (
    <div className="legs-filter-container justify-content-end">
      <div className="quick-adjustment">
        <Typeahead
          id="quick-adjustments-legs"
          className="quick-legs"
          labelKey="label"
          multiple
          onChange={handleLegsUpdate}
          options={intentArray}
          placeholder="Filter Legs..."
          positionFixed
        />
      </div>
    </div>
  );
};

export default LegsFilter;
