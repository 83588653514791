import { getAllOrders } from 'common/api/orders';
import React, {
  useState, useEffect, createContext, useMemo, useCallback
} from 'react';
import PropTypes from 'prop-types';
import { oneOfTypes, runPropTypes } from 'common/propTypes';
import { ORDER_TYPES, RUN_TYPES } from 'common/constants/index';
import { getContextConfig, getSlippageBasedOrders } from './helper';
import {
  getWeekdayAbbreviation,
  getBackTestFilteredStrategies,
  getParseTradingSymbol,
  getDaysToExpiry,
  transformCaseIntentsStructures,
  getInstrumentType,
  getLegLable
} from '../components/BackTestFilters/helper';
import { optimizeJobs } from './metrics/formula';
import { defaultBackTestFilterConfig } from '../../../Strategies/config';

const propTypes = {
  runIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  jobIds: PropTypes.arrayOf(PropTypes.array).isRequired,
  runType: PropTypes.string.isRequired,
  showBy: oneOfTypes,
  children: PropTypes.element,
  orderType: PropTypes.string,
  run: runPropTypes,
  ordersAccessTokens: PropTypes.shape({}),
  optimizeBy: PropTypes.string,
  setCheckedRunIds: PropTypes.func
};

const { paper: { value: paper } } = ORDER_TYPES;

const defaultProps = {
  showBy: 'all',
  children: null,
  orderType: paper,
  run: {},
  ordersAccessTokens: window.ordersAccessTokens,
  optimizeBy: null,
  setCheckedRunIds: null
};

export const OrderContext = createContext(null);

const OrderStore = ({
  runIds, jobIds, runType, showBy, children, orderType, run, ordersAccessTokens, optimizeBy, setCheckedRunIds
}) => {
  const [state, setState] = useState({
    isLoading: true,
    rawOrders: [],
    orders: [],
    contextConfig: { orders: [], transactions: [] },
    filterConfigs: defaultBackTestFilterConfig,
  });

  const isMarketplaceAuthorRun = _.get(run, 'is_strategy_author', false);
  const ignoreInCompleteTransactionUntil = _.get(run, 'ignore_incomplete_orders_until', null);
  const quantConfig = _.get(run, 'quant_config', {});
  const intentConfig = useMemo(() => transformCaseIntentsStructures(quantConfig?.cases || []),
    [quantConfig?.cases]);
  const { intentMapObject, intentArray } = intentConfig;

  const {
    filterConfigs, rawOrders, orders
  } = state;
  const {
    weekDays, daysToExpiry, legs, slippageConfig: { slippage, slippageType }
  } = filterConfigs;
  const isBacktest = runType === RUN_TYPES.historic;

  const onHandleSlippage = (newSlippage, newSlippageType = slippageType) => {
    setState((prevState) => ({
      ...prevState,
      filterConfigs: {
        ...prevState.filterConfigs,
        slippageConfig: { slippage: newSlippage, slippageType: newSlippageType },
      },
    }));
  };

  const processOrders = useCallback((transactionsorders) => {
    return _.map(transactionsorders, (order) => {
      const instrumentType = getInstrumentType(order.trading_symbol, order.option_config_index);
      return {
        ...order,
        day: getWeekdayAbbreviation(order.order_time),
        instrument_type: instrumentType,
        days_to_expiry: getDaysToExpiry(getParseTradingSymbol(order.trading_symbol), order.order_time),
        leg: getLegLable(
          intentMapObject,
          order.case_index,
          order.adjustment_config_index,
          order.option_config_index,
          instrumentType,
        ),
      };
    });
  }, []);

  useEffect(() => {
    Promise.all(_.map(runIds, (runId, idx) => {
      return getAllOrders({
        runId, jobIds: jobIds[idx], runType, orderType, ordersAccessTokens
      });
    })).then((result) => {
      const flattenedOrders = _.flattenDeep(result);
      console.time();
      setState((prevState) => ({
        ...prevState,
        rawOrders: isBacktest ? processOrders(flattenedOrders) : flattenedOrders,
        isLoading: false,
      }));
      console.timeEnd();
    });
  }, [runIds, isBacktest, processOrders]);

  const hasFilterConfigs = weekDays.length || daysToExpiry.length || legs.length
  || (!_.isEmpty(filterConfigs.slippageConfig) && Number(slippage) !== 0);

  useEffect(() => {
    if (!rawOrders.length) return;
    if (isBacktest) {
      setState((prevState) => ({
        ...prevState,
        orders: hasFilterConfigs ? getBackTestFilteredStrategies(rawOrders, filterConfigs) : rawOrders,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        orders: Number(slippage) === 0 ? rawOrders
          : getSlippageBasedOrders(rawOrders, slippage, slippageType),
      }));
    }
  }, [isBacktest, rawOrders, filterConfigs, slippage, slippageType]);

  useEffect(() => {
    if (orders.length) {
      const originalContextConfig = getContextConfig(
        orders,
        runIds,
        showBy,
        runType,
        orderType,
        ignoreInCompleteTransactionUntil,
        isMarketplaceAuthorRun,
        slippage,
        slippageType,
        filterConfigs
      );

      const optimized = optimizeJobs(originalContextConfig.transactions, optimizeBy, _.cloneDeep(runIds));

      setState((prevState) => ({
        ...prevState,
        contextConfig: {
          ...originalContextConfig,
          transactions: optimized.transactions,
        },
      }));

      if (setCheckedRunIds) setCheckedRunIds(optimized.runIds);
    } else {
      setState((prevState) => ({
        ...prevState,
        contextConfig: { orders, transactions: [] },
      }));
    }
  }, [showBy, orders, optimizeBy]);

  return (
    <OrderContext.Provider
      value={{
        ...state.contextConfig,
        ...state.filterConfigs.slippageConfig,
        ...state,
        showBy,
        onHandleSlippage,
        intentArray,
        setFilterConfigs: (newConfigs) => setState((prevState) => ({
          ...prevState,
          filterConfigs: newConfigs,
        })),
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

OrderStore.propTypes = propTypes;
OrderStore.defaultProps = defaultProps;

export default OrderStore;
